import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

const LL = dynamic(()=> import("@/hoc/LazyLoader"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const InfoGraphics = dynamic(()=> import("../../../../components/home/InfoGraphics"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const GenericSlider = dynamic(()=> import("./GenericSlider"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const PromotionBanner = dynamic(()=> import("./PromotionBanner"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const TopGrid = dynamic(()=> import("./TopGrid"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const HeroProduct = dynamic(()=> import("./HeroProduct"),
  {loading: () => <p className="loading-box"></p>,ssr:true }
);

const IRLayout = props => {
	const [isMobile, setMobile] = useState<boolean>(true)
  const { TopGridProps, AcServicesProps, LargeApplianceRepairProps, ApplianceRepairUnderpriceProps, CompleteMobileProtectionProps, ProtectionPlansStartingAtPriceProps, HeroProductProps, PromotionBannerOneProps, PromotionBannerTwoProps, PromotionBannerThreeProps } = props
  useEffect(()=>{ setMobile(window.innerWidth < 481) })
  return (
    <>
      <TopGrid {...TopGridProps} bgColor="white_bg"/>
      <LL><InfoGraphics variant='secondary' title="Why Choose Us" bgColor="gray_bg"/></LL>
      <LL><PromotionBanner {...PromotionBannerThreeProps} bgColor="white_bg" banner="one"/></LL>
      <LL><GenericSlider {...AcServicesProps} bgColor="light_bg" number="One"/></LL>
      { isMobile && <hr className="bottom_border"/> }
      <LL><GenericSlider {...ApplianceRepairUnderpriceProps} bgColor="light_bg" number="One"/></LL>
      <LL><PromotionBanner {...PromotionBannerTwoProps} bgColor="white_bg" banner="two"/></LL>
      <hr className="bottom_border"/>
      {/* <LL><GenericSlider {...CompleteMobileProtectionProps} bgColor="white_bg" number="Three"/></LL> */} {/* Temorarily commenting complete mobile protection protection section */}
      <LL><GenericSlider {...ProtectionPlansStartingAtPriceProps} bgColor="light_bg" number="Two"/></LL>
      <hr className="bottom_border"/>
      <LL><HeroProduct {...HeroProductProps} isIr={true} bgColor="light_bg"/></LL>
    </>
  )
}

export default IRLayout;